import { render, staticRenderFns } from "./a.vue?vue&type=template&id=0d2d8f16&scoped=true&"
var script = {}
import style0 from "./a.vue?vue&type=style&index=0&id=0d2d8f16&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d2d8f16",
  null
  
)

export default component.exports